<template>
  <div id="resource">
    <div class="conent">
      <div class="header">
        <h1>标注成果</h1>
      </div>
      <Table
        :loading="loading"
        height="650"
        :columns="columns"
        :data="markData"
        @on-row-click="inDetail"
        style="width: 100%"
      ></Table>
    </div>
    <!--弹框区-->
    <!--快速拼图显示-->
    <Modal
      v-model="MapShou"
      class-name="addModel-modal"
      fullscreen
      footer-hide
      @on-cancel="MapCancel"
    >
      <p slot="header">
        <span>{{ mapName }}</span>
      </p>
      <div class="ContentBox">
        <div
          class="mapContent"
          id="mapContent"
          style="width: 100%; height: 100%"
        ></div>
      </div>
    </Modal>
    <Modal
      v-model="reMarkNameModal"
      :loading="reMarkNameModalLoading"
      @on-cancel="renameCancel"
      @on-ok="renameOK"
      title="重命名"
    >
      <Input
        type="text"
        style="margin: 20px; width: 480px"
        v-model.trim="selectedName"
        @on-change="reMarkNameModalLoading = true"
        maxlength="15"
        show-word-limit
        placeholder="请输入标注成果的名称"
      >
      </Input>
    </Modal>
  </div>
</template>

<script>
import Api from "@/utils/api.js";
export default {
  name: "index",

  data() {
    return {
      userInfo: {},
      teamID: -1,
      selectedID: "",
      selectedName: "",
      reMarkNameModal: false,
      reMarkNameModalLoading: true,

      columns: [
        {
          title: "标注名称",
          key: "markName",
          align: "center",
        },

        {
          title: "操作",
          align: "center",
          render: (h, params) => {
            return h("div", [
              // h(
              //   'Button',
              //   {
              //     props: {
              //       type: 'primary',
              //     },

              //     on: {
              //       click: (e) => {
              //         e.stopPropagation();
              //         this.detailBtn(params.row.id);
              //       },
              //     },
              //   },
              //   '查看'
              // ),
              h(
                "Button",
                {
                  props: {
                    type: "info",
                  },
                  style: {
                    margin: "0 15px",
                  },
                  on: {
                    click: (e) => {
                      e.stopPropagation();
                      this.reMarkName(params.row);
                    },
                  },
                },
                "重命名"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                  },
                  on: {
                    click: (e) => {
                      e.stopPropagation();
                      this.del(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      loading: false,
      markData: [],
      mapName: "",
      MapShou: false,
      map: null,
      imageLayer: null,
      materialImg: null,
      //消防
    };
  },

  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem("user_info"));
    // if (sessionStorage.getItem('team_id') === null) {
    //   this.teamID = -1;
    //   this.getMapList();
    // } else {
    //   this.teamID = Number(sessionStorage.getItem('team_id'));
    //   this.getMapList();
    // }
    // // this.init_map();
    // // this.init_map_2();
    // this.$_bus.$off('change_Team').$on('change_Team', (value) => {
    //   this.teamID = value;
    //   this.changePage(1);
    // });
    this.getList();
  },

  methods: {
    inDetail(params) {},
    // 获取快速拼图列表
    getList() {
      this.loading = true;
      let tid = 0;
      if (
        sessionStorage.getItem("team_id") === null ||
        Number(sessionStorage.getItem("team_id")) === -1
      ) {
        tid = this.userInfo.team_id;
      } else {
        tid = Number(sessionStorage.getItem("team_id"));
      }
      this.$post(Api.getMapMarkList(), {
        team_id: tid,
      }).then((res) => {
        this.loading = false;
        // console.log(res);
        this.markData = res.data;
      });
    },
    // 重命名
    reMarkName(n) {
      this.selectedID = n.id;
      this.selectedName = n.markName;
      this.reMarkNameModal = true;
      this.reMarkNameModalLoading = true;
    },
    renameOK() {
      if (this.selectedName == "" || this.selectedName == null) {
        this.$Message.warning("标注成果名称不能为空");
        this.reMarkNameModalLoading = false;
      } else {
        this.$post(Api.updateMapMarkName(), {
          id: this.selectedID,
          markName: this.selectedName,
        }).then((res) => {
          console.log(res);
          this.reMarkNameModal = false;
          this.getList();
        });
      }
    },
    renameCancel() {
      this.selectedID = "";
      this.selectedName = "";
    },
    del(n) {
      this.selectedID = n.id;
      this.selectedName = n.markName;
      this.$Modal.confirm({
        title: "<p>是否删除标注成果<b>" + n.markName,
        content: "<p>一旦删除无法复原</p>",
        loading: true,
        onOk: () => {
          this.$post(Api.deleteMapMark(), {
            id: this.selectedID,
          }).then((res) => {
            this.$Message.success(res.msg_customer);
            this.$Modal.remove();
            this.getList();
          });
        },
        onCancel: () => {},
      });
    },
    // 创建地图
    init_map() {
      let that = this;
      let layer = [];
      //高德地图区------------------------------------------
      // 引入瓦片图-平面
      if (this.$online) {
        layer = [new AMap.TileLayer()];
      } else {
        layer = [
          new AMap.TileLayer({
            getTileUrl: function (x, y, z) {
              return Api.GaoDeStreetMap() + z + "/" + x + "/" + y + ".png";
            },
            zIndex: 10,
          }),
        ];
      }

      this.map = new AMap.Map("mapContent", {
        resizeEnable: true,
        expandZoomRange: true,
        zoom: 18, //地图显示的缩放级别
        zooms: [3, 18],
        center: this.$Center,
        layers: layer,
      });
    },
    // 加载快速拼图
    toLoadMapImg(row) {
      let location = "";
      if (row.angle_data) {
        location = JSON.parse(row.angle_data);
      }
      this.map.clearMap();
      //高德地图区========================================
      if (location) {
        console.log("快速拼图详情");
        this.imageLayer = null;
        this.imageLayer = new AMap.ImageLayer({
          bounds: new AMap.Bounds(
            [location.leftdown.lng, location.leftdown.lat],
            [location.rightup.lng, location.rightup.lat]
          ),
          url: row.path, // 图片 Url
          zooms: [3, 18], // 设置可见级别，[最小级别，最大级别]
          zIndex: 12,
        });

        // 将图层添加至地图实例;
        this.map.add(this.imageLayer);
        this.map.setCenter([location.leftdown.lng, location.leftdown.lat]);
      }
    },
    //关闭弹窗
    MapCancel() {
      //   this.imageLayer && this.map.remove(this.imageLayer);
      //   this.materialImg && this.viewer.entities.remove(this.materialImg);
    },
  },

  beforeDestroy() {
    // if (this.viewer != '') {
    //   this.viewer.destroy();
    //   this.viewer = '';
    // }
  },
};
</script> 

<style scoped lang="scss">
#resource {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 24px 20px;
  .conent {
    width: calc(100% - 40px);
    min-width: 800px;
    // margin: 24px 20px;
    padding: 24px 20px;
    position: absolute;
    // background-color: #fff;
    // box-shadow: 0 3px 6px #ddd;
    border-radius: 4px;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    .header {
      display: flex;
      align-items: center;
      h1 {
        font-size: 30px;
        margin-right: 30px;
      }
      margin-bottom: 20px;
    }

    .Page {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
/deep/.addModel-modal {
  .action-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .action {
      padding: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      opacity: 0.6;
      .img {
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
      span {
        color: #666666;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .selected {
      opacity: 1;
    }
    .Separator {
      width: 1px;
      height: 120px;
      background-color: #d6d6d6;
    }
  }
}
/deep/.ivu-modal-body {
  padding: 0;
  .ContentBox {
    width: 100%;
    height: 100%;
    position: absolute;
    .mapContent {
      width: 100%;
      height: 100%;
    }
    .map3DContent {
      width: 100%;
      height: 100%;
    }
    .switch {
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 0.1rem;
      position: absolute;
      top: 10px;
      right: 10px;
      opacity: 0.6;
      background-color: #333;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 80%;
        height: auto;
      }
    }
  }
}
/deep/.MapShou-modal {
  .ContentBox {
    .top {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        cursor: pointer;
        display: block;
      }
    }
    .Content {
      width: 100%;
      height: 800px;
      display: flex;
      justify-content: space-between;
      .left {
        width: 45%;
        margin-left: 40px;
      }
      .right {
        width: 45%;
      }
      .editBox {
        width: 50px;
        height: 650px;
        background-color: #f8f8f9;
      }
      .box {
        width: 100%;
        height: 650px;
        margin-bottom: 20px;
        position: relative;
        .map,
        .img {
          width: 100%;
          height: 100%;
          background-color: #e6e6e6;
        }
        h2 {
          position: absolute;
          bottom: 10px;
          right: 15px;
          font-size: 24px;
          font-weight: bold;
          color: #3860ee;
          text-shadow: 1px 1px 0px #fff, -1px -1px 0px #fff, 2px 2px 0px #fff,
            -2px -2px 0px #fff, 3px 3px 0px #fff, -3px -3px 0px #fff;
        }
        h3 {
          position: absolute;
          bottom: 10px;
          right: 15px;
          font-size: 24px;
          font-weight: bold;
          color: #ff021a;
          text-shadow: 1px 1px 0px #fff, -1px -1px 0px #fff, 2px 2px 0px #fff,
            -2px -2px 0px #fff, 3px 3px 0px #fff, -3px -3px 0px #fff;
        }
        img {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
      .text {
        width: 100%;
        height: 100px;
        /*padding: 10px 20px;*/
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-size: 24px;
          span {
            font-size: 20px;
          }
        }
      }
    }
    .amap-scale-text {
      color: #fff;
    }
  }
}
</style>